.bc__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: var(--color-footer);
}

.bc__footer-heading {
    width: 100%;
    text-align: center;

    margin-bottom: 3rem;
}

.bc__footer-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
}

.bc__footer-links {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;

    width: 100%;
    text-align: left;
}

.bc__footer-links div {
    max-width: 250px;
    margin: 1rem;

    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    margin-right: 1rem;
    margin-left: 1rem;
}

.bc__footer-links_logo {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.bc__footer-links_logo img {
    width: 138.56px;
    height: 88.96px;

    margin-bottom: 1rem;
}

.bc__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 13px;
    line-height: 15px;
    color: #fff;
}

.bc__footer-links_div h4 {
    font-size: 16px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;

    margin-bottom: 0.9rem;
}

.bc__footer-links_div p {
    font-size: 13px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;

    margin: 0.5rem 0;
    cursor: pointer;
}

@media screen and (max-width: 850px) {
    .bc__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .bc__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .bc__footer-links div {
        margin: 1rem 0;
    }

    .bc__footer-btn p {
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .bc__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}
